#page3 {

    height: fit-content;
    background: rgba(0, 0, 0, 0.589);
    padding-bottom: 15px;
    padding-top: 48px;

    h1 {
        color: white;
    }

    .cardholder {
        width: 90%;
        height: fit-content;
        margin-left: auto;
        margin-right: auto;
        margin-top: 50px;
        display: grid;
        grid-auto-rows: 1fr;
        grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
        grid-gap: 50px;
        padding-bottom: 35px;

        .mobile-tab {
            width: auto;
            height: auto;
            background: transparent;
            outline: none;
            border: none;
            padding: 0;
        }

        .card {
            height: 100%;
            border: 3px solid black;
            background: rgba(255, 255, 255, 0.808);
            box-shadow: black 0em .2em .5em .05em;
            z-index: 1;

            a {
                color: black;
            }

            div {
                height: fit-content;
            }

            .cardImg {
                height: 80px;
                width: 80px;
                transform: scale(.8, .8);
                float: left;
            }

            .cardTitle {
                display: flex;
                align-items: center;
                justify-content: center;
                height: fit-content;
                padding: 25px 0px;
                font-size: 2.2em;
            }

            .cardText p {
                position: relative;
                clear: left;
                padding: 18px;
                box-sizing: border-box;
                font-size: 1.3em;
            }
        }

        .arrow {
            display: none;
        }
    }
}
