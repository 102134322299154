@mixin font($font-family, $font-file) {
    @font-face {
        font-family: $font-family;
        src: url($font-file+'.ttf');
        /*src: url($font-file+'.eot?#iefix') format('embedded-opentype'),
            url($font-file+'.woff') format('woff'),
            url($font-file+'.ttf') format('truetype'),
            url($font-file+'.svg#'+$font-family) format('svg');*/
        //font-weight: normal;
        //font-style: normal;
    }
}

@mixin phone {
    @media (max-width: 599px) {
        @content;
    }
}

@mixin tablet-portrait {
    @media (min-width: 600px) {
        @content;
    }
}

@mixin tablet-landscape{
    @media (min-width: 900px) {
        @content;
    }
}

@mixin desktop{
    @media (min-width: 1200px) {
        @content;
    }
}

@mixin big-desktop {
    @media (min-width: 1800px) {
        @content;
    }
}

